.mt_form_item {
  display: flex;
  align-items: center;

  > label {
    display: flex;
    gap: 5px;
    margin-right: 10px;
    align-items: center;
  }

  &-delete {
    width: 15px;
    height: 15px;
    min-width: 15px;
    cursor: pointer;
  }

  &-required {
    color: #8c2e2e;
  }

  &-inputs,
  &-variants {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &-count {
    max-width: 25px;
    min-width: 25px;
    text-align: center;
  }

  &-variant {
    display: flex;
    gap: 5px;
    align-items: center;
    & + & {
      margin-left: 15px;
    }
  }

  &-variant-content {
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 500ms;

    &.disabled {
      color: #999;
      cursor: not-allowed;
      filter: opacity(40%) blur(0.4px);

      * {
        color: #999 !important;
      }

      [class*="-control"] {
        border-bottom-color: #999;
      }
    }
  }
}
