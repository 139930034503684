.vw_look_form {
  margin-bottom: 20px;

  &_container {
    display: flex;

    @media (max-width: 728px) {
      flex-direction: column;
    }
  }
}
