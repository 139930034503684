.vw_look_form_sidebar {
  width: 100%;
  min-width: 402px;
  max-width: 402px;
  height: calc(100vh - 140px);
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #92a69a;
  margin-left: 20px;

  @media (max-width: 859px) {
    min-width: 272px;
    width: 272px;
    max-width: 272px;
  }

  @media (max-width: 728px) {
    margin: 20px 0 0 0;
    width: 100%;
    min-width: unset;
    max-width: unset;
    height: calc(100vh - 100vw - 120px);
  }

  &-header {
    background: #92a69a;
    color: #f9f9f9;
    padding: 10px;
  }

  &-header + &-header {
    border-top: 1px solid #f9f9f9;
  }

  &-body {
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}
