@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

:root:root {
  --toastify-color-success: #92a69a;
  --toastify-color-warning: #d9c8a9;

  --fc-today-bg-color: rgba(146, 166, 154, 0.3);
  --fc-border-color: #92a69a;
  --fc-highlight-color: transparent;
}

* {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: #555;
}

body {
  margin: 0;
}

button {
  border: 1px solid;
  padding: 5px 10px;
  color: #f9f9f9;
  cursor: pointer;
  transition: 500ms;

  &:hover {
    opacity: 0.8;
  }
  &:focus-visible {
    outline: none;
    opacity: 0.5;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.icon-button {
    line-height: 0;
    padding: 5px;
    border: 0;

    svg {
      width: 20px;
      height: 20px;

      * {
        color: #f9f9f9;
      }
    }
  }
}

input[type="text"] {
  width: 100%;
  height: 17px;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #999;
  position: relative;
  padding: 0;
  display: block;
  outline: none;

  &::placeholder {
    color: #999;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.radio {
  height: 16px;
  width: 16px;
  min-width: 16px;

  &:hover {
    input[type="radio"]:before {
      background: rgba(217, 200, 169, 0.5);
    }
  }

  & input[type="radio"] {
    cursor: pointer;
    margin: 0;
    position: relative;
    width: 0;

    &:before {
      background: transparent;
      border: 1px solid #d9c8a9;
      border-radius: 50%;
      content: "";
      height: 14px;
      left: 0;
      position: absolute;
      top: 0;
      transition: 500ms;
      width: 14px;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus-visible:before {
      opacity: 0.5;
      background: rgba(217, 200, 169, 0.5);
    }

    &:after {
      content: "";
      height: 10px;
      width: 10px;
      left: 3px;
      background: #d9c8a9;
      opacity: 0;
      position: absolute;
      top: 3px;
      transition: 500ms;
      border-radius: 50%;
    }

    &:disabled {
      cursor: not-allowed;

      &:before {
        border-color: #ccc;
        background: #ececec;
      }

      &:checked:before {
        background: #ccc;
      }
    }

    &:checked:after {
      opacity: 1;
    }
  }
}

.checkbox {
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;

  &:hover {
    input[type="checkbox"]:before {
      background: rgba(217, 200, 169, 0.5);
    }
  }

  & input[type="checkbox"] {
    cursor: pointer;
    margin: 0;
    position: relative;
    width: 0;

    &:before {
      background: transparent;
      border: 1px solid #d9c8a9;
      content: "";
      height: 14px;
      left: 0;
      position: absolute;
      top: 0;
      transition: 500ms;
      width: 14px;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus-visible:before {
      opacity: 0.5;
      background: rgba(217, 200, 169, 0.5);
    }

    &:after {
      border: solid #f9f9f9;
      border-width: 0 2px 2px 0;
      content: "";
      height: 9px;
      left: 5px;
      opacity: 0;
      position: absolute;
      top: 1px;
      transform: rotate(45deg);
      transition: 500ms;
      width: 4px;
    }

    &:disabled {
      cursor: not-allowed;

      &:before {
        border-color: #ccc;
        background: #ececec;
      }

      &:checked:before {
        background: #ccc;
      }
    }

    &:checked:focus-visible:before {
      background: #d9c8a9;
    }
    &:checked:before {
      background: #d9c8a9;
    }
    &:checked:after {
      opacity: 1;
    }
  }
}

.react-tooltip.react-tooltip {
  background: #46858c;
  opacity: 1;
  border-radius: 0;
  padding: 7px 10px;
  z-index: 1;
  pointer-events: unset;
  max-width: 300px;

  * {
    color: #f9f9f9;
    font-size: 12px;
  }
}

ul.dashed-list {
  margin: 0;
  padding: 0 0 0 6.5px;
  list-style-type: "–";

  li {
    padding-left: 5px;
  }

  li + li {
    margin-top: 5px;
  }
}
