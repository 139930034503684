.cr.cr.cr {
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;

  .fc-daygrid-day-number {
    z-index: auto;
    width: 100%;
  }

  .fc-col-header-cell {
    background: #92a69a;
    padding: 7.75px 0;

    * {
      color: #f9f9f9;
      font-weight: 400;
    }
  }

  .fc-toolbar-title {
    font-size: 13px;
    font-weight: 400;
    width: 79px;
    text-align: center;
  }

  .fc-toolbar-chunk {
    display: flex;
    align-items: center;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }

  .fc-button {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;

    &:hover {
      background: transparent;
      opacity: 1;
    }

    &:focus-visible {
      opacity: 1;
    }

    &:focus {
      box-shadow: 0 0 0 0;
    }

    &:not(:disabled):active {
      background-color: transparent;

      &:focus {
        box-shadow: 0 0 0 0;
      }
    }

    .fc-icon {
      font-size: 27px;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.fc-prev-button,
    &.fc-next-button,
    &.fc-today-button,
    &.fc-wheel-button {
      &:before {
        height: 20px;
      }
      .fc-icon {
        display: none;
      }
    }

    &.fc-prev-button {
      &:before {
        content: url("https://api.iconify.design/material-symbols/arrow-back-ios-new.svg?color=%23555&width=20&height=20");
      }
    }

    &.fc-next-button {
      &:before {
        content: url("https://api.iconify.design/material-symbols/arrow-forward-ios.svg?color=%23555&width=20&height=20");
      }
    }

    &.fc-today-button {
      &:before {
        content: url("https://api.iconify.design/fluent-mdl2/goto-today.svg?color=%23555&width=20&height=20");
      }

      &:disabled {
        opacity: 0.3;
        border: 0;
        background: transparent;
      }
    }

    &.fc-wheel-button {
      &:before {
        content: url("https://api.iconify.design/carbon/chart-radar.svg?color=%23555&width=20&height=20");
      }
    }
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 20px;
  }

  td {
    cursor: pointer;

    &.fc-day-other {
      cursor: default;

      .fc-daygrid-day-events .fc-daygrid-day-bottom a {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;

        &:hover {
          color: #46858c;
          background: transparent;

          div {
            color: #46858c;

            path {
              fill: #46858c;
            }
          }
        }
      }
    }
  }

  .fc-daygrid-day-events {
    margin: 0;
    padding: 0 4px 4px 4px;

    .fc-daygrid-day-bottom {
      margin: 0;

      a {
        margin-top: 4px;
        padding: 0;

        div {
          color: #46858c;
          transition: 500ms;
          display: flex;
          align-items: center;
          gap: 4px;

          svg {
            min-height: 17px;
            min-width: 17px;

            path {
              transition: 500ms;
              fill: #46858c;
            }
          }
        }

        &:hover {
          color: #46858c50;
          background: transparent;

          div {
            color: #46858c50;

            path {
              fill: #46858c50;
            }
          }
        }
      }
    }
  }

  .fc-event.fc-event {
    background: transparent;
    margin: 0;
    border: 0;
    border-radius: 0;
    transition: 500ms;

    .fc-event-main {
      color: #555555;
    }

    .fc-event-title {
      text-overflow: ellipsis;
      padding: 0;
      max-width: calc(100% - 13px);
      white-space: unset;
    }

    &:hover {
      background: transparent;
    }
  }

  .fc-more-popover {
    max-width: 320px;

    .fc-popover-header {
      background: #92a69a;
      font-weight: 700;

      * {
        color: #f9f9f9;
      }
    }

    .fc-popover-body {
      background: #f9f9f9;
      padding: 7px;

      .fc-more-popover-misc {
        display: none;
      }

      .fc-event-title.checked {
        text-decoration: line-through;
      }
    }
  }
}
