.cr_day {
  display: flex;
  flex-direction: column;

  &-widgets {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
}
