.cr_day_medications-header {
  display: flex;
  gap: 0 5px;
  background: #92a69a;
  color: #f9f9f9;
  padding: 10px;
  align-items: center;

  svg {
    width: 17px;
    height: 17px;

    path {
      fill: #f9f9f9;
    }
  }
}
