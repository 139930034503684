.bw_toolbar {
  display: flex;
  gap: 0 15px;
  width: 100%;
  justify-content: center;
  margin-top: 10px;

  &-save {
    background: #92a69a;
  }

  &-cancel {
    background: #d9c8a9;
  }

  &-delete {
    background: #8c2e2e;
  }
}
