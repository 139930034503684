.bw_wheel {
  width: calc(50% - 25px);
  max-width: 550px;
  min-width: 330px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  canvas {
    width: 100% !important;
    height: auto !important;
  }

  &-add {
    background: #084259;
    width: min-content;
  }
}
