.bw {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .bw_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 20px 50px;
    flex-wrap: wrap;
  }
}
