.mt_course_medications {
  border: 1px solid #92a69a;
  border-top: 0;
  margin: 0 0 20px;
  padding: 10px;

  &-period {
    color: #46858c;
    margin: 0 0 5px 0;

    ul + & {
      margin-top: 10px;
    }
  }
}
