.datepicker {
  text-align: center;
  max-width: 72px;

  //noinspection CssUnusedSymbol
  &.month {
    max-width: 51px;
  }

  &.range {
    min-width: 157px;
    max-width: 157px;
  }
}

.datepicker_input-wrapper {
  width: unset;

  &.month {
    max-width: 51px;
  }
}

.datepicker_calendar {
  .react-datepicker {
    border: 0;
    background: #f9f9f9;
    border-radius: 0;
    box-shadow: 2px 2px 5px #777;

    &__header {
      border-radius: 0;
      border: 1px solid #92a69a;
      border-bottom: 0;
      background: #92a69a;
    }

    &__month {
      margin: 0;
      padding: 0.4rem;
      border: 1px solid #92a69a;
      border-top: 0;
    }

    &__current-month,
    &-year-header {
      color: #f9f9f9;
      font-size: 13px;
      font-weight: 400;
    }

    &__navigation {
      &:hover {
        opacity: 1;

        .react-datepicker__navigation-icon:before {
          border-color: #f9f9f9;
        }
      }
    }

    &__navigation-icon {
      &--next:before {
        left: -3px;
      }

      &--previous:before {
        right: -3px;
      }

      &:before {
        border-color: #f9f9f9;
        border-width: 2px 2px 0 0;
        height: 7px;
        width: 7px;
      }
    }

    &__day-name {
      color: #f9f9f9;
    }

    &__day,
    &__month-text {
      color: #555;
      border-radius: 0;
      transition: 200ms;
      outline: none;

      &:hover {
        background: transparent;
        box-shadow: 1px 1px 0 #d9c8a9, -1px -1px 0 #d9c8a9, 1px -1px 0 #d9c8a9,
          -1px 1px 0 #d9c8a9;
      }

      &--outside-month {
        color: #ccc;
        pointer-events: none;
      }

      &--selected,
      &--in-range,
      &--selecting-range-start,
      &--in-selecting-range {
        background: #92a69a;
        color: #f9f9f9;

        &:hover {
          background: #92a69a;
          box-shadow: none;
        }
      }

      &--keyboard-selected:not(&--in-range):not(&--selecting-range-start):not(
          &--in-selecting-range
        ) {
        background: #f9f9f9;
      }

      &--today {
        text-decoration: underline;
      }
    }

    &__month-text {
      padding: 6px 0;
    }
  }

  .react-datepicker__month--selected {
    background: #92a69a !important;
    color: #f9f9f9;

    &:hover {
      background: #92a69a;
      box-shadow: none;
    }
  }
}

.react-datepicker__input-container {
  display: inline-flex;
  align-items: center;
}

.react-datepicker__close-icon {
  position: static;
  padding: 0 0 0 2px;

  &:after {
    content: url('https://api.iconify.design/radix-icons/cross-2.svg?color=%23d9c8a9&width=13');
    background-color: transparent;
    padding: 0;
    height: 15px;
    width: 15px;
  }
}
