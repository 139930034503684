.bw_date {
  display: flex;
  align-items: center;
  justify-content: center;

  &-icon {
    cursor: pointer;
    height: 20px;
    width: 20px;

    &.disabled {
      cursor: not-allowed;

      path {
        color: #ccc;
      }
    }
  }

  &-text {
    width: 79px;
    text-align: center;
  }

  &-input.bw_date-input {
    text-align: center;
  }

  &-input-wrapper {
    margin: 0 14px;
  }
}
