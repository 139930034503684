.cr_day_icons {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px 7px;
  flex-wrap: wrap;

  &-disabled {
    pointer-events: none;
  }

  svg {
    width: 17px;
    height: 17px;
    display: block;

    &:hover {
      opacity: 0.5;
      transition: 500ms;
    }

    path {
      fill: #46858c;
    }
  }
}
