.mt_course_toolbar {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    cursor: pointer;
    width: 15.5px;
    height: 15.5px;
    min-width: 15.5px;
  }

  &-edit path {
    fill: #f9f9f9;
  }

  &-delete path {
    fill: #8c2e2e;
  }
}
