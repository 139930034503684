.bw_todos {
  width: calc(50% - 25px);
  min-width: 330px;
  flex-grow: 1;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
